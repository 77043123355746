export declare type Endpoints =
  {
    method: "POST" | "DELETE" | "GET" | "PATCH" | "PUT";
    summary: string;
    path: string;
  };
export const endpoints: Endpoints[] = [
  {
    method: "GET",
    summary: "Root",
    path: "/"
  },
  {
    method: "POST",
    summary: "Clear Cache",
    path: "/admin/clear_cache"
  },
  {
    method: "POST",
    summary: "Set User Password",
    path: "/admin/set_user_password"
  },
  {
    method: "POST",
    summary: "Change Team Icon",
    path: "/admin/change_team_icon/{team_id}"
  },
  {
    method: "GET",
    summary: "Search For Match League",
    path: "/admin/match/league"
  },
  {
    method: "POST",
    summary: "Delete Events From Match",
    path: "/admin/delete_events_from_match"
  },
  {
    method: "POST",
    summary: "Test New Statistics",
    path: "/admin/test_new_statistics"
  },
  {
    method: "GET",
    summary: "Get Match",
    path: "/admin/match"
  },
  {
    method: "GET",
    summary: "Test",
    path: "/admin/test"
  },
  {
    method: "GET",
    summary: "Duplicate",
    path: "/admin/duplicate"
  },
  {
    method: "GET",
    summary: "Test Notification",
    path: "/admin/test_stmt"
  },
  {
    method: "GET",
    summary: "Test Stat",
    path: "/admin/test_stat"
  },
  {
    method: "POST",
    summary: "Login With Email Password",
    path: "/auth/login"
  },
  {
    method: "POST",
    summary: "Secure Login With Email Password",
    path: "/auth/secure_login"
  },
  {
    method: "POST",
    summary: "Secure Login With Tel Password",
    path: "/auth/secure_login_tel"
  },
  {
    method: "POST",
    summary: "Login With Tel And Sms",
    path: "/auth/login_with_tel_and_sms/{tel}"
  },
  {
    method: "POST",
    summary: "Login With Mail And Sms",
    path: "/auth/login_with_mail_and_sms/{mail}"
  },
  {
    method: "POST",
    summary: "Refresh",
    path: "/auth/refresh"
  },
  {
    method: "POST",
    summary: "Get Freshness Token",
    path: "/auth/freshness_token"
  },
  {
    method: "GET",
    summary: "Check Freshness",
    path: "/auth/check_freshness"
  },
  {
    method: "GET",
    summary: "Get Login Information",
    path: "/auth/login_information/{tel_or_mail}"
  },
  {
    method: "POST",
    summary: "Password Forgot Sms",
    path: "/auth/password_forgot_sms/{mail_or_tel}"
  },
  {
    method: "POST",
    summary: "Password Forgot Mail",
    path: "/auth/password_forgot_mail/{mail_or_tel}"
  },
  {
    method: "POST",
    summary: "Check Password Forgot Code",
    path: "/auth/check_password_forgot_code/{email_or_tel}"
  },
  {
    method: "POST",
    summary: "Confirm Password Forgot",
    path: "/auth/password_forgot_confirm/{email_or_tel}"
  },
  {
    method: "POST",
    summary: "Change Password",
    path: "/auth/change_password"
  },
  {
    method: "PATCH",
    summary: "Patch Login Identity",
    path: "/auth/login_identity"
  },
  {
    method: "GET",
    summary: "Get Languages",
    path: "/language/"
  },
  {
    method: "GET",
    summary: "Get Language",
    path: "/language/{language_id}"
  },
  {
    method: "POST",
    summary: "Create User",
    path: "/user/"
  },
  {
    method: "GET",
    summary: "Get Users",
    path: "/user/"
  },
  {
    method: "PATCH",
    summary: "Patch User",
    path: "/user/"
  },
  {
    method: "DELETE",
    summary: "Delete User",
    path: "/user/"
  },
  {
    method: "GET",
    summary: "Get User Me",
    path: "/user/me"
  },
  {
    method: "GET",
    summary: "Get User",
    path: "/user/{user_id}"
  },
  {
    method: "PATCH",
    summary: "Patch User By Id",
    path: "/user/{user_id}"
  },
  {
    method: "DELETE",
    summary: "Delete User By Id",
    path: "/user/{user_id}"
  },
  {
    method: "POST",
    summary: "Register User",
    path: "/user/register"
  },
  {
    method: "POST",
    summary: "Add Scope",
    path: "/user/scope/add/{user_id}"
  },
  {
    method: "POST",
    summary: "Remove Scope",
    path: "/user/scope/remove/{user_id}"
  },
  {
    method: "PUT",
    summary: "Favorite Match",
    path: "/user/favorite/match/{match_id}"
  },
  {
    method: "PUT",
    summary: "Favorite Team",
    path: "/user/favorite/team/{team_id}"
  },
  {
    method: "PUT",
    summary: "Favorite League",
    path: "/user/favorite/league/{league_id}"
  },
  {
    method: "PUT",
    summary: "Unfavorite Match",
    path: "/user/unfavorite/match/{match_id}"
  },
  {
    method: "PUT",
    summary: "Unfavorite Team",
    path: "/user/unfavorite/team/{team_id}"
  },
  {
    method: "PUT",
    summary: "Unfavorite League",
    path: "/user/unfavorite/league/{league_id}"
  },
  {
    method: "PUT",
    summary: "Add My Team",
    path: "/user/add_my_team/{team_id}"
  },
  {
    method: "PUT",
    summary: "Remove My Team",
    path: "/user/remove_my_team/{team_id}"
  },
  {
    method: "PUT",
    summary: "Set Selected My Team",
    path: "/user/set_selected_my_team/{team_id}"
  },
  {
    method: "POST",
    summary: "Change Password",
    path: "/user/password_change"
  },
  {
    method: "POST",
    summary: "Forgot Password",
    path: "/user/password_forgot"
  },
  {
    method: "POST",
    summary: "Confirm Mail",
    path: "/user/email_confirm"
  },
  {
    method: "POST",
    summary: "Confirm Tel",
    path: "/user/tel_confirm"
  },
  {
    method: "GET",
    summary: "Email Available",
    path: "/user/email_available/{email}"
  },
  {
    method: "GET",
    summary: "Username Available",
    path: "/user/username_available/{username}"
  },
  {
    method: "GET",
    summary: "Tel Available",
    path: "/user/tel_available/{tel}"
  },
  {
    method: "POST",
    summary: "Resend Sms",
    path: "/user/resend_sms"
  },
  {
    method: "POST",
    summary: "Update Device Info",
    path: "/user/update_device_info"
  },
  {
    method: "POST",
    summary: "Create Group Request",
    path: "/user/group/request"
  },
  {
    method: "POST",
    summary: "Remove Group",
    path: "/user/group/remove/{team_id}"
  },
  {
    method: "POST",
    summary: "Remove Group From User",
    path: "/user/group/remove/user/{user_id}/team/{team_id}"
  },
  {
    method: "PUT",
    summary: "Block User Group",
    path: "/user/group/block/user/{user_id}/team/{team_id}"
  },
  {
    method: "PUT",
    summary: "Block User",
    path: "/user/block/{user_id}"
  },
  {
    method: "DELETE",
    summary: "Delete User Image",
    path: "/user/image"
  },
  {
    method: "POST",
    summary: "Add Tournament Admin To User",
    path: "/user/tournament/admin/{tournament_id}"
  },
  {
    method: "POST",
    summary: "Add Tournament Manager To User",
    path: "/user/tournament/manager/{tournament_id}"
  },
  {
    method: "GET",
    summary: "Get Area Of Interests",
    path: "/area_of_interest/"
  },
  {
    method: "POST",
    summary: "Create Area Of Interest",
    path: "/area_of_interest/"
  },
  {
    method: "DELETE",
    summary: "Delete Area Of Interest",
    path: "/area_of_interest/{area_of_interest_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Area Of Interest",
    path: "/area_of_interest/{area_of_interest_id}"
  },
  {
    method: "GET",
    summary: "Get League By Id",
    path: "/league/{league_id}"
  },
  {
    method: "PATCH",
    summary: "Patch League",
    path: "/league/{league_id}"
  },
  {
    method: "DELETE",
    summary: "Delete League",
    path: "/league/{league_id}"
  },
  {
    method: "GET",
    summary: "Get Leagues",
    path: "/league/"
  },
  {
    method: "POST",
    summary: "Create League",
    path: "/league/"
  },
  {
    method: "POST",
    summary: "Add Team To League",
    path: "/league/add_team"
  },
  {
    method: "POST",
    summary: "Remove Team From League",
    path: "/league/remove_team"
  },
  {
    method: "POST",
    summary: "Create Multiple Leagues",
    path: "/league/multiple"
  },
  {
    method: "POST",
    summary: "Set League Order",
    path: "/league/set_order"
  },
  {
    method: "PUT",
    summary: "Deactivate League",
    path: "/league/deactivate/{league_id}"
  },
  {
    method: "POST",
    summary: "Duplicate League",
    path: "/league/duplicate_league/{league_id}/year/{year}"
  },
  {
    method: "POST",
    summary: "Import Playoff Hockey",
    path: "/league/import_playoff_hockey"
  },
  {
    method: "GET",
    summary: "Get League Match By Date",
    path: "/league_matches/date"
  },
  {
    method: "GET",
    summary: "Get League Template",
    path: "/league_template/{name}"
  },
  {
    method: "PUT",
    summary: "Update League Template",
    path: "/league_template/{name}"
  },
  {
    method: "DELETE",
    summary: "Delete League Template",
    path: "/league_template/{name}"
  },
  {
    method: "GET",
    summary: "Get League Templates",
    path: "/league_template/"
  },
  {
    method: "POST",
    summary: "Create League Template",
    path: "/league_template/"
  },
  {
    method: "GET",
    summary: "Get Team By Id",
    path: "/team/{team_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Team",
    path: "/team/{team_id}"
  },
  {
    method: "DELETE",
    summary: "Delete Team",
    path: "/team/{team_id}"
  },
  {
    method: "GET",
    summary: "Get Teams",
    path: "/team/"
  },
  {
    method: "POST",
    summary: "Create Team",
    path: "/team/"
  },
  {
    method: "GET",
    summary: "Get Team By Player And League",
    path: "/team/player/{player_id}/league/{league_id}"
  },
  {
    method: "POST",
    summary: "Add Player To Team",
    path: "/team/{team_id}/add/{player_id}"
  },
  {
    method: "POST",
    summary: "Remove Player From Team",
    path: "/team/{team_id}/remove/{player_id}"
  },
  {
    method: "POST",
    summary: "Change Player Position",
    path: "/team/{team_id}/change/position/{position}/{player_id}"
  },
  {
    method: "GET",
    summary: "Get League History",
    path: "/team/league_history/{team_id}"
  },
  {
    method: "PUT",
    summary: "Take Over Emblem",
    path: "/team/take_over_emblem/{team_from_id}/{team_to_id}"
  },
  {
    method: "GET",
    summary: "Get Tournament Team",
    path: "/team/{team_id}/tournament/{tournament_id}"
  },
  {
    method: "GET",
    summary: "Get Event",
    path: "/event/{event_id}"
  },
  {
    method: "DELETE",
    summary: "Delete Event",
    path: "/event/{event_id}"
  },
  {
    method: "GET",
    summary: "Get Events",
    path: "/event/"
  },
  {
    method: "POST",
    summary: "Add Goal Event",
    path: "/event/goal/{match_id}"
  },
  {
    method: "POST",
    summary: "Add Change Event",
    path: "/event/change/{match_id}"
  },
  {
    method: "POST",
    summary: "Add Chance Event",
    path: "/event/chance/{match_id}"
  },
  {
    method: "POST",
    summary: "Add Card Event",
    path: "/event/card/{match_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Goal Event",
    path: "/event/goal/{event_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Chance Event",
    path: "/event/chance/{event_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Change Event",
    path: "/event/change/{event_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Card Event",
    path: "/event/card/{event_id}"
  },
  {
    method: "GET",
    summary: "Get Goal Types",
    path: "/event/type/goal"
  },
  {
    method: "GET",
    summary: "Get Card Types",
    path: "/event/type/card"
  },
  {
    method: "GET",
    summary: "Get Chance Types",
    path: "/event/type/chance"
  },
  {
    method: "GET",
    summary: "Get Event Type Enum",
    path: "/event/types"
  },
  {
    method: "GET",
    summary: "Get Match",
    path: "/match/{match_id}"
  },
  {
    method: "PATCH",
    summary: "Patch League Match",
    path: "/match/{match_id}"
  },
  {
    method: "DELETE",
    summary: "Delete Match",
    path: "/match/{match_id}"
  },
  {
    method: "GET",
    summary: "Get Matches By Team",
    path: "/match/team/{team_id}"
  },
  {
    method: "GET",
    summary: "Get Matches By League And Date",
    path: "/match/league/{league_id}/date/{match_date}"
  },
  {
    method: "GET",
    summary: "Get Matches By League And Match Day",
    path: "/match/league/{league_id}/match_day/{match_day}"
  },
  {
    method: "GET",
    summary: "Get Match History",
    path: "/match/history/{match_id}"
  },
  {
    method: "POST",
    summary: "Create Match",
    path: "/match/"
  },
  {
    method: "POST",
    summary: "Create Multiple Matches",
    path: "/match/multiple"
  },
  {
    method: "POST",
    summary: "Start Match",
    path: "/match/start/{match_id}"
  },
  {
    method: "POST",
    summary: "Cancel Match",
    path: "/match/cancel/{match_id}"
  },
  {
    method: "POST",
    summary: "Uncancel Match",
    path: "/match/uncancel/{match_id}"
  },
  {
    method: "PUT",
    summary: "Set Match Report",
    path: "/match/report/{match_id}"
  },
  {
    method: "POST",
    summary: "Add Minutes",
    path: "/match/add/{match_id}/{minutes}"
  },
  {
    method: "POST",
    summary: "Finish Match",
    path: "/match/finish/{match_id}"
  },
  {
    method: "GET",
    summary: "Get Random Match Id",
    path: "/match/random"
  },
  {
    method: "GET",
    summary: "Get League Match By Date",
    path: "/league_matches/date"
  },
  {
    method: "GET",
    summary: "Get Match League",
    path: "/matches/v2/league/{match_id}"
  },
  {
    method: "PATCH",
    summary: "Patch League Match",
    path: "/matches/v2/league/{match_id}"
  },
  {
    method: "GET",
    summary: "Get Match Tournament Group",
    path: "/matches/v2/tournament/group/{match_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Tournament Group Match",
    path: "/matches/v2/tournament/group/{match_id}"
  },
  {
    method: "GET",
    summary: "Get Match Tournament Stage",
    path: "/matches/v2/tournament/stage/{match_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Tournament Stage Match",
    path: "/matches/v2/tournament/stage/{match_id}"
  },
  {
    method: "POST",
    summary: "Change Team League Match",
    path: "/matches/v2/league/change_team/{match_id}"
  },
  {
    method: "POST",
    summary: "Switch Team League Match",
    path: "/matches/v2/league/switch_teams/{match_id}"
  },
  {
    method: "POST",
    summary: "Create Match League",
    path: "/matches/v2/league/{league_id}"
  },
  {
    method: "POST",
    summary: "Create Match Tournament Group",
    path: "/matches/v2/group/{tournament_group_id}"
  },
  {
    method: "POST",
    summary: "Create Match Tournament Stage",
    path: "/matches/v2/stage/{tournament_stage_id}"
  },
  {
    method: "GET",
    summary: "Get Matches By Team",
    path: "/matches/v2/team/{team_id}"
  },
  {
    method: "GET",
    summary: "Get Matches By League And Date",
    path: "/matches/v2/league/{league_id}/date/{match_date}"
  },
  {
    method: "GET",
    summary: "Get Matches By League And Match Day",
    path: "/matches/v2/league/{league_id}/match_day/{match_day}"
  },
  {
    method: "GET",
    summary: "Get Match History",
    path: "/matches/v2/history/{match_id}"
  },
  {
    method: "DELETE",
    summary: "Delete Match",
    path: "/matches/v2/{match_id}"
  },
  {
    method: "GET",
    summary: "Get Matches By Tournament",
    path: "/matches/v2/tournament/{tournament_id}"
  },
  {
    method: "GET",
    summary: "Get Home Matches",
    path: "/matches/v2/home"
  },
  {
    method: "GET",
    summary: "Get My Matches",
    path: "/matches/v2/my"
  },
  {
    method: "GET",
    summary: "Get App Version",
    path: "/app/version"
  },
  {
    method: "GET",
    summary: "Get Privacy",
    path: "/app/privacy"
  },
  {
    method: "GET",
    summary: "Get Tos",
    path: "/app/tos"
  },
  {
    method: "GET",
    summary: "Get Privacy",
    path: "/app/privacy/{lang}"
  },
  {
    method: "GET",
    summary: "Get Tos",
    path: "/app/tos/{lang}"
  },
  {
    method: "GET",
    summary: "Get Update Required",
    path: "/app/update_required/{version}"
  },
  {
    method: "GET",
    summary: "Get Agb Level",
    path: "/app/agb_level"
  },
  {
    method: "GET",
    summary: "Get Changelog",
    path: "/app/changelog"
  },
  {
    method: "GET",
    summary: "Get Match Bet Voting By Match",
    path: "/match_bet/{match_id}"
  },
  {
    method: "PUT",
    summary: "Vote Match",
    path: "/match_bet/"
  },
  {
    method: "GET",
    summary: "Get Standing By League",
    path: "/standing/league/all/{league_id}"
  },
  {
    method: "GET",
    summary: "Get Standing By League",
    path: "/standing/league/{league_id}"
  },
  {
    method: "GET",
    summary: "Get Standing By Teams",
    path: "/standing/teams/{team_1_id}/{team_2_id}"
  },
  {
    method: "GET",
    summary: "Get Standing History Of Team",
    path: "/standing/history/team/{team_id}"
  },
  {
    method: "GET",
    summary: "Get Standing By League And Match Day",
    path: "/standing/league/{league}/match_day/{match_day}"
  },
  {
    method: "GET",
    summary: "Get Goal Distribution By Match",
    path: "/goal_distribution/match/{match_id}"
  },
  {
    method: "GET",
    summary: "Get Team Of The Week",
    path: "/man_of_the_match/team/{league_id}/matchday/{match_day}"
  },
  {
    method: "GET",
    summary: "Get Man Of The Match Voting By Match",
    path: "/man_of_the_match/{match_id}"
  },
  {
    method: "POST",
    summary: "Vote Match",
    path: "/man_of_the_match/"
  },
  {
    method: "GET",
    summary: "Get Focused Players",
    path: "/man_of_the_match/focus/{match_id}"
  },
  {
    method: "GET",
    summary: "Get Players By Team",
    path: "/player/team/{team_id}"
  },
  {
    method: "GET",
    summary: "Get Player Positions",
    path: "/player/player_position"
  },
  {
    method: "GET",
    summary: "Get Player",
    path: "/player/{player_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Player",
    path: "/player/{player_id}"
  },
  {
    method: "DELETE",
    summary: "Delete Player",
    path: "/player/{player_id}"
  },
  {
    method: "GET",
    summary: "Get Player Card",
    path: "/player/card/{player_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Player Admin",
    path: "/player/admin/{player_id}"
  },
  {
    method: "POST",
    summary: "Create Player",
    path: "/player/"
  },
  {
    method: "POST",
    summary: "Claim Player",
    path: "/player/claim/{player_id}"
  },
  {
    method: "POST",
    summary: "Claim Player Admin",
    path: "/player/claim_admin/{player_id}/{user_id}"
  },
  {
    method: "POST",
    summary: "Un Claim Player",
    path: "/player/un_claim/{player_id}"
  },
  {
    method: "POST",
    summary: "Merge Players",
    path: "/player/merge/{target_player_id}/{source_player_id}"
  },
  {
    method: "GET",
    summary: "Get Lineup By Match And Team",
    path: "/lineup/match/{match_id}/{team}"
  },
  {
    method: "PUT",
    summary: "Put Lineup By Match And Team",
    path: "/lineup/match/{match_id}/{team}"
  },
  {
    method: "GET",
    summary: "Get Last Lineup By Team",
    path: "/lineup/team/{team_id}"
  },
  {
    method: "GET",
    summary: "Get Lineup Types",
    path: "/lineup/types"
  },
  {
    method: "GET",
    summary: "Get Match Dates Today",
    path: "/match_day/today/{amount_days}"
  },
  {
    method: "GET",
    summary: "Get Match Dates Smaller",
    path: "/match_day/smaller/{amount_days}"
  },
  {
    method: "GET",
    summary: "Get Match Dates Bigger",
    path: "/match_day/bigger/{amount_days}"
  },
  {
    method: "GET",
    summary: "Get Match Dates In Month",
    path: "/match_day/month"
  },
  {
    method: "GET",
    summary: "Search",
    path: "/search/{query}"
  },
  {
    method: "GET",
    summary: "Search",
    path: "/search/all/{query}"
  },
  {
    method: "GET",
    summary: "Search Team",
    path: "/search/team/{query}"
  },
  {
    method: "GET",
    summary: "Search League",
    path: "/search/league/{query}"
  },
  {
    method: "GET",
    summary: "Search Player",
    path: "/search/player/{query}"
  },
  {
    method: "GET",
    summary: "Search Location",
    path: "/search/location/{query}"
  },
  {
    method: "GET",
    summary: "Search Match Ids By Teams",
    path: "/search/match/team_1/{team_1_id}/team_2/{team_2_id}"
  },
  {
    method: "GET",
    summary: "Search Match",
    path: "/search/match/{query}"
  },
  {
    method: "GET",
    summary: "Search User",
    path: "/search/user/{query}"
  },
  {
    method: "GET",
    summary: "Search Ad",
    path: "/search/ad/{query}"
  },
  {
    method: "GET",
    summary: "Search Tournament",
    path: "/search/tournament/{query}"
  },
  {
    method: "GET",
    summary: "Search Blog",
    path: "/search/blog/{query}"
  },
  {
    method: "POST",
    summary: "Bulk Search",
    path: "/search/bulk"
  },
  {
    method: "GET",
    summary: "Get Team Statistics",
    path: "/statistics/team/league/{league_id}"
  },
  {
    method: "GET",
    summary: "Get Team Statistics Detail",
    path: "/statistics/team/league/{league_id}/detail/{statistic_key}"
  },
  {
    method: "GET",
    summary: "Get Player Statistics League",
    path: "/statistics/player/league/{league_id}"
  },
  {
    method: "GET",
    summary: "Get Player Statistics League Detail",
    path: "/statistics/player/league/{league_id}/detail/{statistic_key}"
  },
  {
    method: "GET",
    summary: "Get Player Statistics Match Detail",
    path: "/statistics/player/match/{match_id}/detail/{statistic_key}"
  },
  {
    method: "GET",
    summary: "Get Squad Statistic",
    path: "/statistics/squad/team/{team_id}"
  },
  {
    method: "GET",
    summary: "Get Squad Statistics Detail",
    path: "/statistics/squad/team/{team_id}/detail/{statistic_key}"
  },
  {
    method: "GET",
    summary: "Get Squad",
    path: "/squad/{team_id}"
  },
  {
    method: "GET",
    summary: "Get Single Squad",
    path: "/squad/team/{team_id}/player/{player_id}"
  },
  {
    method: "POST",
    summary: "Create Location",
    path: "/location/"
  },
  {
    method: "PATCH",
    summary: "Update Location",
    path: "/location/{location_id}"
  },
  {
    method: "POST",
    summary: "Create Sponsor",
    path: "/sponsor/team/{team_id}"
  },
  {
    method: "PUT",
    summary: "Patch Sponsor",
    path: "/sponsor/{sponsor_id}"
  },
  {
    method: "DELETE",
    summary: "Delete Sponsor",
    path: "/sponsor/{sponsor_id}"
  },
  {
    method: "POST",
    summary: "Transfer Player",
    path: "/transfer/{player_id}/from/{old_team_id}/to/{new_team_id}"
  },
  {
    method: "GET",
    summary: "Get Transfer",
    path: "/transfer/area_of_interest/{area_of_interest_id}"
  },
  {
    method: "DELETE",
    summary: "Delete Transfer",
    path: "/transfer/{transfer_id}"
  },
  {
    method: "GET",
    summary: "Get Blogs",
    path: "/blog/"
  },
  {
    method: "POST",
    summary: "Create Blog",
    path: "/blog/"
  },
  {
    method: "PATCH",
    summary: "Patch Blog",
    path: "/blog/"
  },
  {
    method: "GET",
    summary: "Get Blog",
    path: "/blog/{blog_id}"
  },
  {
    method: "DELETE",
    summary: "Delete Blog",
    path: "/blog/{blog_id}"
  },
  {
    method: "GET",
    summary: "Get Hockey Hcp",
    path: "/monitorwerbung/hockey/hcp"
  },
  {
    method: "GET",
    summary: "Get By Team And Language",
    path: "/monitorwerbung/{team_id}/{lang_suffix}"
  },
  {
    method: "GET",
    summary: "Get By Team And Language And Timestamp",
    path: "/monitorwerbung/{team_id}/{lang_suffix}/{timestamp}"
  },
  {
    method: "GET",
    summary: "Get Out Target",
    path: "/out/target"
  },
  {
    method: "GET",
    summary: "Redirect",
    path: "/out/{key}/{user_id}"
  },
  {
    method: "GET",
    summary: "Redirect Without User",
    path: "/out/{key}"
  },
  {
    method: "GET",
    summary: "Get Ad",
    path: "/ad/{ad_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Ad",
    path: "/ad/{ad_id}"
  },
  {
    method: "DELETE",
    summary: "Delete Ad",
    path: "/ad/{ad_id}"
  },
  {
    method: "GET",
    summary: "Get Ads By Position",
    path: "/ad/position/{position}"
  },
  {
    method: "POST",
    summary: "Create Ad",
    path: "/ad/"
  },
  {
    method: "POST",
    summary: "Create Multiple Ads",
    path: "/ad/multiple"
  },
  {
    method: "GET",
    summary: "Get Punishment",
    path: "/punishment/{punishment_id}"
  },
  {
    method: "POST",
    summary: "Create Punishment",
    path: "/punishment/"
  },
  {
    method: "GET",
    summary: "Get Punishments By League",
    path: "/punishment/league/{league_id}"
  },
  {
    method: "POST",
    summary: "Create Bulletin",
    path: "/bulletin/"
  },
  {
    method: "GET",
    summary: "Get Bulletins",
    path: "/bulletin/"
  },
  {
    method: "GET",
    summary: "Get Bulletin",
    path: "/bulletin/{bulletin_id}"
  },
  {
    method: "POST",
    summary: "Create Post",
    path: "/post/"
  },
  {
    method: "GET",
    summary: "Get Post",
    path: "/post/{post_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Post",
    path: "/post/{post_id}"
  },
  {
    method: "DELETE",
    summary: "Delete Post",
    path: "/post/{post_id}"
  },
  {
    method: "POST",
    summary: "Like Post",
    path: "/post/like/{post_id}"
  },
  {
    method: "POST",
    summary: "Unlike Post",
    path: "/post/unlike/{post_id}"
  },
  {
    method: "POST",
    summary: "Comment Post",
    path: "/comment/post/{post_id}"
  },
  {
    method: "GET",
    summary: "Get Comments From Post",
    path: "/comment/post/{post_id}"
  },
  {
    method: "POST",
    summary: "Comment Comment",
    path: "/comment/comment/{comment_id}"
  },
  {
    method: "POST",
    summary: "Like Comment",
    path: "/comment/like/{comment_id}"
  },
  {
    method: "POST",
    summary: "Unlike Comment",
    path: "/comment/unlike/{comment_id}"
  },
  {
    method: "DELETE",
    summary: "Delete Comment",
    path: "/comment/{comment_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Comment",
    path: "/comment/{comment_id}"
  },
  {
    method: "GET",
    summary: "Get User Feed",
    path: "/feed/user"
  },
  {
    method: "GET",
    summary: "Get Match Feed",
    path: "/feed/match/{match_id}"
  },
  {
    method: "GET",
    summary: "Get Region Feed",
    path: "/feed/region/{region_id}"
  },
  {
    method: "GET",
    summary: "Get My Feed",
    path: "/feed/my"
  },
  {
    method: "GET",
    summary: "Get Of User Feed",
    path: "/feed/user/{user_id}"
  },
  {
    method: "POST",
    summary: "Upload Image",
    path: "/media/image"
  },
  {
    method: "GET",
    summary: "Download Image",
    path: "/media/download/{media_id}"
  },
  {
    method: "GET",
    summary: "Get Badge",
    path: "/badge/{badge_type}"
  },
  {
    method: "PATCH",
    summary: "Patch Badges",
    path: "/badge/{badge_type}"
  },
  {
    method: "GET",
    summary: "Get Badges",
    path: "/badge/"
  },
  {
    method: "POST",
    summary: "Create Badges",
    path: "/badge/"
  },
  {
    method: "PUT",
    summary: "Give User Badge",
    path: "/badge/give"
  },
  {
    method: "DELETE",
    summary: "Remove User Badge",
    path: "/badge/remove"
  },
  {
    method: "PUT",
    summary: "Check League Match Import",
    path: "/import/check/match"
  },
  {
    method: "PUT",
    summary: "Check League Import",
    path: "/import/check/league"
  },
  {
    method: "PUT",
    summary: "Check Team Import",
    path: "/import/check/team"
  },
  {
    method: "PUT",
    summary: "Check Squad Import",
    path: "/import/check/squad"
  },
  {
    method: "POST",
    summary: "Start League Match Import",
    path: "/import/match"
  },
  {
    method: "POST",
    summary: "Start League Import",
    path: "/import/league"
  },
  {
    method: "POST",
    summary: "Start Team Import",
    path: "/import/team"
  },
  {
    method: "POST",
    summary: "Start Squad Import",
    path: "/import/squad"
  },
  {
    method: "POST",
    summary: "Get Task",
    path: "/task/"
  },
  {
    method: "POST",
    summary: "Update All Statistics",
    path: "/task/update_all_statistics"
  },
  {
    method: "POST",
    summary: "Update All Standings",
    path: "/task/update_all_standings"
  },
  {
    method: "POST",
    summary: "Calculate Team Of The Week",
    path: "/task/calculate_team_of_the_week"
  },
  {
    method: "POST",
    summary: "Calculate Player Ratings",
    path: "/task/calculate_player_ratings"
  },
  {
    method: "POST",
    summary: "Refresh Squads",
    path: "/task/refresh_all_squads"
  },
  {
    method: "POST",
    summary: "Update Hubspot",
    path: "/task/update_hubspot"
  },
  {
    method: "POST",
    summary: "Calculate Insider Score",
    path: "/task/calculate_insider_score"
  },
  {
    method: "GET",
    summary: "Get Sports",
    path: "/sport/"
  },
  {
    method: "GET",
    summary: "Get Health",
    path: "/health/ping"
  },
  {
    method: "GET",
    summary: "Update New",
    path: "/update/update"
  },
  {
    method: "POST",
    summary: "Export Player Score",
    path: "/export/player_score/{area_of_interest_id}"
  },
  {
    method: "POST",
    summary: "Request Image Upload",
    path: "/user_media/image"
  },
  {
    method: "POST",
    summary: "Request Video Upload",
    path: "/user_media/video"
  },
  {
    method: "POST",
    summary: "Request Tus Upload",
    path: "/user_media/tus"
  },
  {
    method: "DELETE",
    summary: "Delete Image",
    path: "/user_media/image/{image_id}"
  },
  {
    method: "DELETE",
    summary: "Delete Video",
    path: "/user_media/video/{video_id}"
  },
  {
    method: "GET",
    summary: "Get Max Upload Length Seconds",
    path: "/user_media/max_upload_length_seconds"
  },
  {
    method: "GET",
    summary: "Get Aspect Ration",
    path: "/user_media/ratio/{image_type}"
  },
  {
    method: "GET",
    summary: "Get Salto Widget",
    path: "/widget/salto"
  },
  {
    method: "GET",
    summary: "Get Next Match Widget",
    path: "/widget/next_match/{team_id}"
  },
  {
    method: "GET",
    summary: "Get Last Match Widget",
    path: "/widget/last_match/{team_id}"
  },
  {
    method: "GET",
    summary: "Get Tournament Looser Bracket Widget",
    path: "/widget/tournament/looser_bracket/{tournament_id}/{lang}"
  },
  {
    method: "GET",
    summary: "Get Tournament Stages Widget",
    path: "/widget/tournament/stages/{tournament_id}/{lang}"
  },
  {
    method: "GET",
    summary: "Get Tournament Groups Widget",
    path: "/widget/tournament/groups/{tournament_id}/{lang}"
  },
  {
    method: "GET",
    summary: "Auth Via Url",
    path: "/a/{code}"
  },
  {
    method: "GET",
    summary: "Read Update Counter News",
    path: "/update_counter/blog"
  },
  {
    method: "GET",
    summary: "Read Update Counter Bulletins",
    path: "/update_counter/bulletin"
  },
  {
    method: "GET",
    summary: "Read Update Counter Transfers",
    path: "/update_counter/transfer"
  },
  {
    method: "GET",
    summary: "Read Update Counter Feed",
    path: "/update_counter/feed"
  },
  {
    method: "GET",
    summary: "Get League Categories",
    path: "/league_category/"
  },
  {
    method: "GET",
    summary: "Get Lineup User Engagement",
    path: "/user_engagement/lineup/{area_of_interest_id}/{year}"
  },
  {
    method: "GET",
    summary: "Get Event User Engagement",
    path: "/user_engagement/events/{area_of_interest_id}/{year}"
  },
  {
    method: "GET",
    summary: "Get Region Comparison",
    path: "/user_engagement/region_comparison"
  },
  {
    method: "GET",
    summary: "Pool Status",
    path: "/system_info/sqlalchemy/pool"
  },
  {
    method: "GET",
    summary: "Get Tournament Pdf",
    path: "/tournament/pdf/{tournament_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Tournament Pdf",
    path: "/tournament/pdf/{tournament_id}"
  },
  {
    method: "GET",
    summary: "Get Tournament",
    path: "/tournament/{tournament_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Tournament",
    path: "/tournament/{tournament_id}"
  },
  {
    method: "DELETE",
    summary: "Delete Tournament",
    path: "/tournament/{tournament_id}"
  },
  {
    method: "GET",
    summary: "Get Tournament Secrets",
    path: "/tournament/secrets/{tournament_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Tournament Secrets",
    path: "/tournament/secrets/{tournament_id}"
  },
  {
    method: "POST",
    summary: "Create Tournament",
    path: "/tournament/"
  },
  {
    method: "GET",
    summary: "Get Tournament By Date",
    path: "/tournament/area_of_interest/{area_of_interest_id}/date/{date}"
  },
  {
    method: "PUT",
    summary: "Set Order",
    path: "/tournament/set_order"
  },
  {
    method: "POST",
    summary: "Add Team To Tournament By Name",
    path: "/tournament/{tournament_id}/team/name"
  },
  {
    method: "POST",
    summary: "Add Team To Tournament",
    path: "/tournament/{tournament_id}/team/{team_id}"
  },
  {
    method: "DELETE",
    summary: "Remove Team From Tournament",
    path: "/tournament/{tournament_id}/team/id/{team_id}"
  },
  {
    method: "POST",
    summary: "Create Tournament Stage",
    path: "/tournament/stage/{tournament_id}"
  },
  {
    method: "GET",
    summary: "Get Tournament Stages",
    path: "/tournament/stage/{tournament_id}"
  },
  {
    method: "DELETE",
    summary: "Remove Stage From Tournament",
    path: "/tournament/stage/{tournament_stage_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Tournament Stage",
    path: "/tournament/stage/{tournament_stage_id}"
  },
  {
    method: "GET",
    summary: "Get Tournament Group Standings",
    path: "/tournament/group/standings/{tournament_id}"
  },
  {
    method: "GET",
    summary: "Get Tournament Groups",
    path: "/tournament/group/{tournament_id}"
  },
  {
    method: "POST",
    summary: "Add Group To Tournament",
    path: "/tournament/group/{tournament_id}"
  },
  {
    method: "DELETE",
    summary: "Remove Group From Tournament",
    path: "/tournament/group/{tournament_group_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Group",
    path: "/tournament/group/{tournament_group_id}"
  },
  {
    method: "POST",
    summary: "Add Team To Group By Id",
    path: "/tournament/group/{tournament_group_id}/team/{team_id}/"
  },
  {
    method: "DELETE",
    summary: "Remove Team From Group",
    path: "/tournament/group/{tournament_group_id}/team/{team_id}/"
  },
  {
    method: "GET",
    summary: "Get Tournament Statistics",
    path: "/tournament_statistics/{tournament_id}"
  },
  {
    method: "GET",
    summary: "Get Aspect Ration",
    path: "/upload/ratio/{image_type}"
  },
  {
    method: "GET",
    summary: "Get Daily Cache Metrics",
    path: "/metrics/cache"
  },
  {
    method: "POST",
    summary: "Visit Profile",
    path: "/profile_visit/visit_profile/{player_id}"
  },
  {
    method: "GET",
    summary: "Get Profile Visits",
    path: "/profile_visit/visit_profile/{player_id}"
  },
  {
    method: "GET",
    summary: "Get Amount Events Created",
    path: "/insider/events/created"
  },
  {
    method: "GET",
    summary: "Get Amount Events Last Edited",
    path: "/insider/events/last_edited"
  },
  {
    method: "GET",
    summary: "Get Amount Lineups Created",
    path: "/insider/lineup/created"
  },
  {
    method: "GET",
    summary: "Get Amount Lineups Last Edited",
    path: "/insider/lineup/last_edited"
  },
  {
    method: "GET",
    summary: "Get Regions",
    path: "/region/"
  },
  {
    method: "POST",
    summary: "Create Region",
    path: "/region/"
  },
  {
    method: "GET",
    summary: "Get Region",
    path: "/region/{region_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Region",
    path: "/region/{region_id}"
  },
  {
    method: "DELETE",
    summary: "Delete Region",
    path: "/region/{region_id}"
  },
  {
    method: "GET",
    summary: "Query Logs",
    path: "/audit_log/"
  },
  {
    method: "GET",
    summary: "Get Share Amount",
    path: "/s/amount"
  },
  {
    method: "GET",
    summary: "Get And Register Share",
    path: "/s/{user_id}"
  },
  {
    method: "GET",
    summary: "Get Player Rating Match",
    path: "/player_rating/match/{match_id}/player/{player_id}"
  },
  {
    method: "GET",
    summary: "Get Rating Match",
    path: "/player_rating/match/{match_id}"
  },
  {
    method: "GET",
    summary: "Get Player Rating With Matches",
    path: "/player_rating/player/{player_id}"
  },
  {
    method: "GET",
    summary: "Get Player Rating Ranking",
    path: "/player_rating/ranking/player/{player_id}"
  },
  {
    method: "GET",
    summary: "Get Player Rating Explanation",
    path: "/player_rating/explanation/match/{match_id}"
  },
  {
    method: "GET",
    summary: "Get User Notifications",
    path: "/user_notification/"
  },
  {
    method: "PATCH",
    summary: "Patch User Notifications",
    path: "/user_notification/"
  },
  {
    method: "GET",
    summary: "Get User Follow All",
    path: "/user_follow/all"
  },
  {
    method: "GET",
    summary: "Get User Follow League",
    path: "/user_follow/league"
  },
  {
    method: "GET",
    summary: "Get User Follow Matches",
    path: "/user_follow/matches"
  },
  {
    method: "GET",
    summary: "Get User Follow Players",
    path: "/user_follow/players"
  },
  {
    method: "GET",
    summary: "Get User Follow Teams",
    path: "/user_follow/teams"
  },
  {
    method: "PATCH",
    summary: "Patch User Follow Match",
    path: "/user_follow/match/{match_id}"
  },
  {
    method: "PUT",
    summary: "Put User Follow Match",
    path: "/user_follow/match/{match_id}"
  },
  {
    method: "DELETE",
    summary: "Delete User Follow Match",
    path: "/user_follow/match/{match_id}"
  },
  {
    method: "PATCH",
    summary: "Patch User Follow Player",
    path: "/user_follow/player/{player_id}"
  },
  {
    method: "PUT",
    summary: "Put User Follow Player",
    path: "/user_follow/player/{player_id}"
  },
  {
    method: "DELETE",
    summary: "Delete User Follow Player",
    path: "/user_follow/player/{player_id}"
  },
  {
    method: "PATCH",
    summary: "Patch User Follow Team",
    path: "/user_follow/team/{team_id}"
  },
  {
    method: "PUT",
    summary: "Put User Follow Team",
    path: "/user_follow/team/{team_id}"
  },
  {
    method: "DELETE",
    summary: "Delete User Follow Team",
    path: "/user_follow/team/{team_id}"
  },
  {
    method: "PUT",
    summary: "Put User Follow League",
    path: "/user_follow/league/{league_id}"
  },
  {
    method: "DELETE",
    summary: "Delete User Follow League",
    path: "/user_follow/league/{league_id}"
  },
  {
    method: "GET",
    summary: "Get User Follow Count Player",
    path: "/user_follow/count/player/{player_id}"
  },
  {
    method: "POST",
    summary: "Send Notification To User",
    path: "/notification/user"
  },
  {
    method: "PUT",
    summary: "Refresh Beat",
    path: "/beat/refresh"
  },
  {
    method: "GET",
    summary: "Get Active Users Per Area Of Interest",
    path: "/monitoring/active_users_per_area_of_interest"
  },
  {
    method: "GET",
    summary: "Get Users Per Area Of Interest",
    path: "/monitoring/users_per_area_of_interest"
  },
  {
    method: "POST",
    summary: "Create Feedback",
    path: "/feedback/"
  },
  {
    method: "GET",
    summary: "Get Feedbacks",
    path: "/feedback/"
  },
  {
    method: "GET",
    summary: "Get Periodic Notifications",
    path: "/periodic_notification/"
  },
  {
    method: "POST",
    summary: "Create Periodic Notification",
    path: "/periodic_notification/"
  },
  {
    method: "GET",
    summary: "Get Periodic Notification",
    path: "/periodic_notification/{periodic_notification_id}"
  },
  {
    method: "PATCH",
    summary: "Patch Periodic Notification",
    path: "/periodic_notification/{periodic_notification_id}"
  },
  {
    method: "DELETE",
    summary: "Delete Periodic Notification",
    path: "/periodic_notification/{periodic_notification_id}"
  }
];
